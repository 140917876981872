import intlTelInput from "intl-tel-input"
import { NSerializeJson } from "nserializejson"

export class ValidateForm {
  constructor() {
    this.initForms()
  }

  initForms() {

    document.querySelectorAll(".contact-form").forEach((form) => {
      const formBtn = form.querySelector(".form-btn")
      const nameInput = form.querySelector(".name_input")
      const emailInput = form.querySelector(".email_input")
      const phoneInput = form.querySelector("input[type='tel']")
      const checkboxInput = form.querySelector(".checkbox")

      const nameError = form.querySelector(".name-error")
      const emailError = form.querySelector(".email-error")
      const phoneError = form.querySelector(".phone-error")
      const alertIconName = form.querySelector(".alert-name__image")
      const alertIconEmail = form.querySelector(".alert-email__image")
      const alertIconPhone = form.querySelector(".alert-phone__image")

      formBtn.disabled = true

      let iti
      if (phoneInput) {
        iti = intlTelInput(phoneInput, {
          loadUtils: () => import("intl-tel-input/utils"),
          initialCountry: "auto",
          geoIpLookup: (callback) => {
            fetch("https://ipapi.co/json")
              .then((res) => res.json())
              .then((data) => callback(data.country_code))
              .catch(() => callback("us"))
          },
          separateDialCode: true,
          strictMode: true,
        })
      }

      function resetErrors() {
        if (nameError) nameError.classList.add("hide")
        if (emailError) emailError.classList.add("hide")
        if (phoneError) phoneError.classList.add("hide")

        if (nameInput) nameInput.classList.remove("error")
        if (emailInput) emailInput.classList.remove("error")
        if (phoneInput) phoneInput.classList.remove("error")
        if (checkboxInput) checkboxInput.classList.remove("error")

        if (alertIconName) alertIconName.classList.add("hide")
        if (alertIconEmail) alertIconEmail.classList.add("hide")
        if (alertIconPhone) alertIconPhone.classList.add("hide")
      }

      function validateForm() {
        let isValid = true
        resetErrors()

        // Проверка имени
        if (nameInput && nameInput.value.trim().length < 3 && nameInput.value.trim().length > 0) {
          nameError.classList.remove("hide")
          nameInput.classList.add("error")
          alertIconName.classList.remove("hide")
          isValid = false
        }

        // Проверка email
        if (
          emailInput &&
          emailInput.value.trim() &&
          !/\S+@\S+\.\S+/.test(emailInput.value.trim())
        ) {
          emailError.classList.remove("hide")
          emailInput.classList.add("error")
          alertIconEmail.classList.remove("hide")
          isValid = false
        }

        // Проверка телефона с использованием intl-tel-input
        if (phoneInput && phoneInput.value.trim().length > 0) {
          if (iti && iti.isValidNumber()) {
            phoneError.classList.add("hide")
            phoneInput.classList.remove("error")
            alertIconPhone.classList.add("hide")
          } else {
            phoneError.classList.remove("hide")
            phoneInput.classList.add("error")
            alertIconPhone.classList.remove("hide")
            isValid = false
          }
        }

        // Проверка чекбокса
        if (checkboxInput && !checkboxInput.checked) {
          checkboxInput.classList.add("error")
          isValid = false
        }

        formBtn.disabled =
          !isValid ||
          (nameInput && nameInput.value.trim().length === 0) ||
          (emailInput && emailInput.value.trim().length === 0) ||
          (phoneInput && phoneInput.value.trim().length === 0) ||
          (checkboxInput && checkboxInput.required && !checkboxInput.checked)
      }

      // Валидация имени
      if (nameInput) {
        nameInput.addEventListener("input", () => {
          if (nameInput.value.trim().length >= 3) {
            nameError.classList.add("hide")
            nameInput.classList.remove("error")
          } else if (nameInput.value.trim().length > 0) {
            nameError.classList.remove("hide")
            nameInput.classList.add("error")
          }
          validateForm()
        })
      }

      // Валидация email
      if (emailInput) {
        emailInput.addEventListener("input", () => {
          if (/\S+@\S+\.\S+/.test(emailInput.value.trim())) {
            emailError.classList.add("hide")
            emailInput.classList.remove("error")
            alertIconEmail.classList.add("hide")
          } else if (emailInput.value.trim().length > 0) {
            emailError.classList.remove("hide")
            emailInput.classList.add("error")
            alertIconEmail.classList.remove("hide")
          }
          validateForm()
        })
      }

      // Валидация телефона
      if (phoneInput) {
        phoneInput.addEventListener("input", () => {
          // Если поле не пустое и номер валиден, скрыть ошибку
          if (phoneInput.value.trim().length > 0 && iti && iti.isValidNumber()) {
            phoneError.classList.add("hide")
            phoneInput.classList.remove("error")
            alertIconPhone.classList.add("hide")
          } else {
            phoneError.classList.remove("hide")
            phoneInput.classList.add("error")
            alertIconPhone.classList.remove("hide")
          }
          validateForm()
        })
      }

      // Валидация чекбокса
      if (checkboxInput) {
        checkboxInput.addEventListener("change", () => {
          validateForm()
        })
      }

      // Form submission handler
      // if (form) {
      //   form.addEventListener("submit", (e) => {
      //     e.preventDefault()
      //     validateForm()
      //     if (!formBtn.disabled) {
      //       const formAction = form.getAttribute("action")
      //       const formData = new FormData(form)
      //       const succesfullRequest = document.querySelector("[data-success=\"success_modal\"]")
      //       const BadRequest = document.querySelector("[data-success=\"error_modal\"]")
      //       const closeFormBtns = document.querySelectorAll(".close-btn")
      //       formBtn.disabled = true

      //       closeFormBtns.forEach((closeBtn) => {
      //         closeBtn.addEventListener("click", () => {
      //           succesfullRequest.classList.remove("openRequest")
      //           document.body.classList.remove("lock")
      //         })
      //       })

      //       fetch(formAction, {
      //         method: "POST",
      //         body: formData,
      //         headers: {
      //           Accept: "application/json",
      //           "X-CSRF-TOKEN": document.querySelector("meta[name=\"csrf-token\"]").getAttribute("content") || "",
      //         },
      //       })
      //         .then((response) => {
      //           if (!response.ok) {
      //             BadRequest && BadRequest.classList.add("openRequest")

      //             throw new Error(`HTTP error! Status: ${response.status}`)
      //           }
      //           return response.json()
      //         })
      //         .then((data) => {
      //           console.log("Form submitted successfully!", data)
      //           // succesfullRequest && succesfullRequest.classList.add("openRequest")
      //           if (succesfullRequest) {
      //             succesfullRequest.classList.add("openRequest")
      //             document.body.classList.add("lock")
      //           }
      //         })
      //         .catch((error) => {
      //           console.error("Error submitting form:", error)
      //         })
      //         .finally(() => {
      //           formBtn.disabled = false
      //         })
      //     }
      //   })
      // }

      if (form) {
        form.addEventListener("submit",  async (e) => {
          e.preventDefault()
          validateForm()

          if (!formBtn.disabled) {
            const succesfullRequest = document.querySelector(`[data-success='${form.getAttribute("data-success-modal") || "success_modal"}']`)
            const BadRequest = document.querySelector(`[data-success='${form.getAttribute("data-error-modal") || "error_modal"}']`)

            formBtn.disabled = true

            try {
              let form_options = {
                method: form.method || "post",
                headers: form.type === "json" ?
                  {
                    "Content-Type": "application/json",
                    "X-CSRF-TOKEN": document.querySelector("meta[name=\"csrf-token\"]").getAttribute("content") || "",
                  } :
                  {
                    "X-CSRF-TOKEN": document.querySelector("meta[name=\"csrf-token\"]").getAttribute("content") || "",
                  },
                body: form.type === "json"
                  ? JSON.stringify(NSerializeJson.serializeForm(form))
                  : new FormData(form)
              }

              const response = await fetch(form.action, form_options)

              if (response.status !== 200) {
                BadRequest && BadRequest.classList.add("openRequest")
                return
              }
              succesfullRequest.classList.add("openRequest")

              if(form.getAttribute("data-lock")) {
                document.body.classList.add("lock")
              }
              console.info("Request is success")
              form.reset()
            } catch (error) {
              console.error("Request failed", error)
            }
          }
        })
      }

      validateForm()
    })

    const closeFormBtns = document.querySelectorAll(".close-btn")

    closeFormBtns.forEach((closeBtn) => {
      closeBtn.addEventListener("click", () => {
        document.querySelector(".openRequest") && document.querySelector(".openRequest").classList.remove("openRequest")
        document.body.classList.remove("lock")
      })
    })
  }
}
