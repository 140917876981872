export class playAudioInit {
  constructor() {
    this.playAudio()
  }

  playAudio() {
    const playBtns = document.querySelectorAll(".record-play_btn")
    const progressBars = document.querySelectorAll(".record_progress")
    const currentTimeDisplays = document.querySelectorAll(".record_current-time")
    const audios = []

    const audioElements = document.querySelectorAll(".audio_item")

    audioElements.forEach((audio) => audios.push(audio))

    playBtns.forEach((btn, index) => {
      const audio = audios[index]
      const progressBar = progressBars[index]

      btn.addEventListener("click", function () {
        audios.forEach((audioItem, i) => {
          if (audioItem !== audio && !audioItem.paused) {
            audioItem.pause()
          }
        })

        if (audio.paused) {
          audio.play()
          setInterval(() => updateProgress(index), 100)
        } else {
          audio.pause()
        }
      })

      progressBar.addEventListener("input", function () {
        const value = progressBar.value
        const duration = audio.duration
        audio.currentTime = (value / 100) * duration
      })
    })

    function updateProgress(index) {
      const audio = audios[index]
      const progressBar = progressBars[index]
      const currentTimeDisplay = currentTimeDisplays[index]

      const currentTime = audio.currentTime
      const duration = audio.duration
      const progressValue = (currentTime / duration) * 100

      progressBar.value = progressValue

      progressBar.style.background = `linear-gradient(to right, var(--thumb-color, #fff) ${progressValue}%, rgba(255, 255, 255, 0.3) ${progressValue}%)`

      const minutes = Math.floor(currentTime / 60)
      const seconds = Math.floor(currentTime % 60)
      currentTimeDisplay.textContent = `${minutes}:${seconds < 10 ? "0" + seconds : seconds}`
    }
  }
}
