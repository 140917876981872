export class HighlightText {
  constructor() {
    // this.inputListener()
    this.highlightText()
  }

  highlightText() {
    const searchElement = document.querySelector(".articles_search-feild strong")
    if (!searchElement) {
      return
    }

    const searchText = searchElement.textContent.trim()

    if (!searchText) {
      return
    }

    const cardTitles = document.querySelectorAll(".card_title")

    if (cardTitles.length === 0) {
      return
    }

    cardTitles.forEach((title) => {
      const text = title.textContent.trim()

      if (text.includes(searchText)) {
        const safeSearchText = searchText.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1")
        const regex = new RegExp(`(${safeSearchText})`, "gi")
        title.innerHTML = title.innerHTML.replace(regex, "<span class=\"highlight\">$1</span>")
      }
    })
  }

  // highlightText(searchText) {
  //   const paragraphs = document.querySelectorAll("[data-search]")

  //   paragraphs.forEach((paragraph) => {
  //     const content = paragraph.textContent || paragraph.innerText
  //     const searchTextLower = searchText.toLowerCase()

  //     if (content.toLowerCase().includes(searchTextLower)) {
  //       const highlightedText = content.replace(new RegExp(searchText, "gi"), (match) => {
  //         return `<span class="highlight">${match}</span>`
  //       })
  //       paragraph.innerHTML = highlightedText
  //     } else {
  //       paragraph.innerHTML = content
  //     }
  //   })
  // }

  // inputListener() {
  //   const searchInputs = document.querySelectorAll(".search-input")
  //   searchInputs.forEach((input) => {
  //     input.addEventListener("input", (event) => {
  //       const searchText = event.target.value
  //       this.highlightText(searchText)
  //     })
  //   })
  // }
}
