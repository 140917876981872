import { fadeIn, fadeOut } from "../utils/fade"
export class articleCardsInit {
  constructor() {
    this.showArticleCards()
  }

  showArticleCards() {
    const allCardsWraps = document.querySelectorAll(".articles_selected-category")

    allCardsWraps.forEach((blogCardsWrap) => {
      const showMoreBtn = blogCardsWrap.querySelector(".category_btn")
      const blogCards = blogCardsWrap.querySelectorAll(".category_card")

      let visibleCount = 8

      function updateVisibleCards() {
        blogCards.forEach((card, index) => {
          if (index < visibleCount) {
            const delay = 100 * index
            setTimeout(() => {
              if (card.classList.contains("hidden")) {
                fadeIn(
                  card,
                  300,
                  () => {
                    card.classList.remove("hidden")
                  },
                  "block",
                )
              }
            }, delay)
          } else {
            fadeOut(card, 300, () => {
              card.classList.add("hidden")
            })
          }
        })

        if (visibleCount >= blogCards.length) {
          showMoreBtn.style.display = "none"
        }
      }

      showMoreBtn.addEventListener("click", function () {
        visibleCount += 9
        updateVisibleCards()
      })

      updateVisibleCards()
    })
  }
}
