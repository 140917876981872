import Swiper from "swiper"
import { Navigation } from "swiper/modules"
export class initSwiperResize {
  constructor() {
    this.SwiperResize()
  }

  SwiperResize() {
    let swiperFamiliar = null
    const FamiliarSlider = document.querySelector(".familiar_is_slider")

    if (!FamiliarSlider) {
      return
    }

    const wrapper = FamiliarSlider.querySelector(".familiar_card-wrap")
    if (!wrapper) {
      return
    }

    const cards = Array.from(wrapper.querySelectorAll(".familiar_card"))

    if (cards.length === 0) {
      return
    }

    function initSwiper() {
      if (window.innerWidth <= 1024 && !FamiliarSlider.querySelector(".swiperUse")) {
        const swiperContainer = document.createElement("div")
        swiperContainer.classList.add("swiperUse")

        const swiperWrapper = document.createElement("div")
        swiperWrapper.classList.add("swiper-wrapper")

        for (let i = 0; i < cards.length; i += 3) {
          const slide = document.createElement("div")
          slide.classList.add("swiper-slide")

          for (let j = i; j < i + 3 && j < cards.length; j++) {
            slide.appendChild(cards[j])
          }

          swiperWrapper.appendChild(slide)
        }

        swiperContainer.appendChild(swiperWrapper)
        wrapper.prepend(swiperContainer)

        try {
          swiperFamiliar = new Swiper(".swiperUse", {
            spaceBetween: 20,
            modules: [Navigation],
            navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            },
            breakpoints: {
              320: {
                slidesPerView: 1.05,
                spaceBetween: 20,
              },
              565: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
            },
          })
        } catch (error) {
          console.error("Не вдалося ініціалізувати Swiper:", error)
        }
      }
    }

    function destroySwiper() {
      const swiperContainer = FamiliarSlider.querySelector(".swiperUse")
      if (swiperContainer) {
        const swiperSlides = swiperContainer.querySelectorAll(".swiper-slide")

        swiperSlides.forEach((slide) => {
          Array.from(slide.children).forEach((card) => {
            wrapper.appendChild(card) // Ensure the card is re-appended correctly
          })
        })

        swiperContainer.remove()

        if (swiperFamiliar) {
          swiperFamiliar.destroy()
          swiperFamiliar = null
        }
      }
    }

    initSwiper()

    window.addEventListener("resize", () => {
      destroySwiper()
      initSwiper()
    })
  }
}
