export class ContactForm {
  constructor(triggerSelector, formModalSelector, formSelector) {
    this.triggerSelector = triggerSelector
    this.formModalSelector = formModalSelector
    this.formSelector = formSelector
    this.showContactForm()
  }

  showContactForm() {
    const contactBtns = document.querySelectorAll(this.triggerSelector)
    if (!contactBtns.length) {
      return
    }

    const formModal = document.querySelector(this.formModalSelector)
    if (!formModal) return

    const contactFormContainer = document.querySelector(".contact-form-container")
    const closeFormBtn = formModal.querySelector(".close-form-btn")
    const contactFormElement = formModal.querySelector(this.formSelector)

    contactBtns.forEach((btn) => {
      btn.addEventListener("click", () => {
        formModal.classList.add("openContactForm")
        contactFormContainer && contactFormContainer.classList.add("showContactFormContainer")
        document.body.classList.add("lock")
      })
    })

    if (closeFormBtn) {
      closeFormBtn.addEventListener("click", () => {
        formModal.classList.remove("openContactForm")
        contactFormContainer && contactFormContainer.classList.remove("showContactFormContainer")
        document.body.classList.remove("lock")
      })
    }

    contactFormElement.addEventListener("submit", (e) => {
      e.preventDefault()

      const formBtn = formModal.querySelector(".form-btn")
      const isValid = !formBtn.disabled

      if (isValid) {
        formModal.classList.remove("openContactForm")
        contactFormContainer.classList.remove("showContactFormContainer")
        document.body.classList.remove("lock")
        console.log("Form submitted successfully!")
      }
    })

    document.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        formModal.classList.remove("openContactForm")
        contactFormContainer.classList.remove("showContactFormContainer")
        document.body.classList.remove("lock")
      }
    })
  }
}
