import { fadeIn, fadeOut } from "../utils/fade"
export class featureItemsInit {
  constructor() {
    this.featuresWrap = document.querySelectorAll(".features-body_wrap")
    this.showFeaturesItem()
  }

  showFeaturesItem() {
    this.featuresWrap.forEach((featuresWrap) => {
      if (!featuresWrap) return

      const showBtn = featuresWrap.querySelector(".btn")
      if (!showBtn) return

      const cardWrap = featuresWrap.querySelector(".features-body_card-wrap")
      const cards = cardWrap ? cardWrap.querySelectorAll(".features-body_card") : []

      showBtn.addEventListener("click", (event) => {
        const cardList = cardWrap.querySelectorAll(".features-body_card")
        if (cardList.length === 0) return

        const textShow = showBtn.getAttribute("data-text-show")
        const textHide = showBtn.getAttribute("data-text-hide")

        if (showBtn.dataset.state === "show-all") {
          cards.forEach((card, index) => {
            const delay = index * 100
            setTimeout(() => {
              if (card.classList.contains("hide")) {
                fadeIn(card, 300, () => { 
                  card.classList.remove("hide")
                },"flex")
              }
            }, delay)
          })

          showBtn.dataset.state = "show-less"
          showBtn.textContent = textHide
        } else {
          cards.forEach((card, index) => {
            if (index >= 3) {
              fadeOut(card, 300, () => {
                card.classList.add("hide")
              })
            }
          })
          showBtn.dataset.state = "show-all"
          showBtn.textContent = textShow
        }
      })

      if (cards.length > 3) {
        cards.forEach((card, index) => {
          if (index >= 3) {
            card.classList.add("hide")
          }
        })
        showBtn.classList.remove("hidden")
        showBtn.dataset.state = "show-all"
        showBtn.textContent = showBtn.getAttribute("data-text-show")
      } else {
        showBtn.classList.add("hidden")
      }
    })
  }
}
