import { fadeIn, fadeOut } from "../utils/fade"
export class initPaymnetToggle {
  constructor() {
    this.paymentTogglePlan()
  }

  paymentTogglePlan() {
    const paymentToggler = document.querySelectorAll(".payment_toggler")
    const planText = document.querySelectorAll(".active_toggler .plan_text")

    if (!paymentToggler) {
      return
    }
    paymentToggler.forEach((toggler) => {
      toggler.addEventListener("click", () => {
        toggler.classList.toggle("toggle")

        planText.forEach((planText) => {
          planText.classList.toggle("current_plan")
        })

        const monthlyPriceWrap = document.querySelectorAll(".monthly-price")
        const annualPriceWrap = document.querySelectorAll(".annual-price")

        if (toggler.classList.contains("toggle")) {
          // Show monthly prices and hide annual prices
          monthlyPriceWrap.forEach((wrap) => {
            setTimeout(() => {
              fadeIn(
                wrap,
                300,
                () => {
                  wrap.classList.add("current-price-wrap")
                },
                "flex",
              )
            }, 300)
          })
          annualPriceWrap.forEach((wrap) => {
            fadeOut(wrap, 300, () => {
              wrap.classList.remove("current-price-wrap")
            })
          })
          document.querySelectorAll(".save-descr").forEach((item) => {
            item.classList.add("save-descr_hide")
          })
        } else {
          // Show annual prices and hide monthly prices
          annualPriceWrap.forEach((wrap) => {
            setTimeout(() => {
              fadeIn(
                wrap,
                300,
                () => {
                  wrap.classList.add("current-price-wrap")
                },
                "flex",
              )
            }, 300)
          })
          monthlyPriceWrap.forEach((wrap) => {
            fadeOut(wrap, 300, () => {
              wrap.classList.remove("current-price-wrap")
            })
          })
          document.querySelectorAll(".save-descr").forEach((item) => {
            item.classList.remove("save-descr_hide")
          })
        }
      })
    })
  }
}
