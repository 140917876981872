export class langSwitchInit {
  constructor() {
    this.langSwitch()
  }

  langSwitch() {
    const langWrap = document.querySelectorAll(".lang_switcher")

    if (langWrap.length === 0) {
      return
    }

    // Проходим по каждому родительскому элементу
    langWrap.forEach((wrap) => {
      const langBtns = wrap.querySelectorAll(".lang_btn") // Находим все кнопки внутри текущего элемента langWrap

      langBtns.forEach((btn) => {
        btn.addEventListener("click", () => {
          // Убираем активный класс с всех кнопок
          langBtns.forEach((button) => {
            button.classList.remove("active_lang")
          })

          // Добавляем активный класс только на ту кнопку, по которой кликнули
          btn.classList.add("active_lang")
        })
      })
    })
  }
}
