export class currencyListInit {
  constructor() {
    this.openCurrencyList()
  }

  openCurrencyList() {
    const dropdowns = document.querySelectorAll(".currency-dropdown")
    dropdowns.forEach((dropdown) => {
      const dropdownButton = dropdown.querySelector(".dropdown-button")
      const dropdownList = dropdown.querySelector(".dropdown-list")
      const dropdownItems = dropdown.querySelectorAll(".dropdown-item")

      dropdownButton.addEventListener("click", (e) => {
        e.stopPropagation()
        dropdownList.classList.toggle("open")
        dropdownButton.classList.toggle("active")
      })

      dropdownItems.forEach((item) => {
        item.addEventListener("click", () => {
          const selectedText = item.textContent

          dropdownButton.querySelector("span").textContent = selectedText
          dropdownList.classList.remove("open")
        })
      })

      document.addEventListener("click", (e) => {
        if (!dropdown.contains(e.target)) {
          dropdownList.classList.remove("open")
        }
      })
    })
  }
}
