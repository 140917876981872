export class dialTabsInit {
  constructor() {
    this.dialTabInit()
  }

  dialTabInit() {
    const tabsContainer = document.getElementById("dialTabs")
    if (!tabsContainer) {
      return
    }
    const tabButtons = tabsContainer.querySelectorAll(".tabs__btn")
    const tabItems = tabsContainer.querySelectorAll(".tabs__item")
    let isLocked = false
    const duration = 300

    function fadeIn($el, delay, callback) {
      $el.style.transition = `opacity ${delay}ms`
      $el.style.opacity = "1"

      setTimeout(() => {
        $el.style.opacity = "1"
        $el.style.display = "flex"
        callback && callback($el)
      }, delay)
    }

    function fadeOut($el, delay, callback) {
      $el.style.transition = `opacity ${delay}ms`
      $el.style.opacity = "0"

      setTimeout(() => {
        $el.style.display = "none"
        callback && callback($el)
      }, delay)
    }

    function hideAllTabs() {
      tabItems.forEach((item) => {
        fadeOut(item, duration, () => {
          item.classList.remove("active")
        })
      })
    }

    function showTab(tabId) {
      const targetItem = tabsContainer.querySelector(`[data-tab-content="${tabId}"]`)
      if (targetItem) {
        fadeIn(targetItem, duration, () => {
          targetItem.classList.add("active")
        })
      }
    }

    function changeActiveButton(tabId) {
      tabButtons.forEach((btn) => {
        const tabTargetID = btn.dataset.tabId?.toLowerCase()

        if (tabTargetID === tabId) {
          btn.classList.add("active")
        } else {
          btn.classList.remove("active")
        }
      })
    }

    tabButtons.forEach((button) => {
      button.addEventListener("click", function () {
        const targetTab = this.dataset.tabId?.toLowerCase()

        if (isLocked) return
        isLocked = true

        hideAllTabs()
        showTab(targetTab)

        changeActiveButton(targetTab)

        setTimeout(() => {
          isLocked = false
        }, duration)
      })
    })

    const firstButton = tabButtons[0]
    const firstTabId = firstButton.dataset.tabId?.toLowerCase()

    if (firstTabId) {
      firstButton.classList.add("active")
      showTab(firstTabId)
    }
  }
}
