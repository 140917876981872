import Swiper from "swiper"
import { Navigation, Pagination } from "swiper/modules"
import { Marquee } from "./lib/marquee"
import { Tabs } from "./components/tabs/tabs.js"
import { Accordion } from "./components/accordions/accordion.js"
import { ValidateForm } from "./services/validateForm.js"
import { ShowProposalForm } from "./services/showProposalForm.js"
import { showBurger } from "./services/burgerInit.js"
import { dialTabsInit } from "./services/dialTabInit.js"
import { ContactForm } from "./services/showContactForm.js"
import { articleCardsInit } from "./services/showArticleCards.js"
import { playVideoInit } from "./services/playVideo.js"
import { articleSearchInit } from "./services/openArticleSearch.js"
import { headerDropDownInit } from "./services/initHeaderDropDown.js"
import { langSwitchInit } from "./services/langSwitch.js"
import { featureItemsInit } from "./services/showFeaturesItem.js"
import { playAudioInit } from "./services/audioPlay.js"
import { initSwiperResize } from "./services/swiperResize.js"
import { currencyListInit } from "./services/openCurrencyList.js"
import { initPaymnetToggle } from "./services/togglePaymentPlan.js"
import { HighlightText } from "./services/highlightText.js"
import { ToggleItemsInit } from "./services/toggleItemsInit.js"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { CurrencyDropdown } from "./services/CurrencySwitcher.js"

/**
 * Show hidden elements as modals, popups, tooltips, etc.
 * In a first load webpage html elements loads before styles. That's all
 * elements with transition is jumping on a page.
 * @type { NodeListOf<HTMLElement> }
 * */
const hiddenElements = document.querySelectorAll(".important_none")

window.addEventListener("load", async () => {
  hiddenElements.forEach(($el) => $el.classList.remove("important_none"))
  //   const linksToCheckTelPattern = document.querySelectorAll("a")
  //   linksToCheckTelPattern &&
  //     linksToCheckTelPattern.forEach((linkTel) => convertLinkToTelPattern(linkTel))
})

new ValidateForm()
new ShowProposalForm()
new showBurger()
new dialTabsInit()
new articleCardsInit()
new playVideoInit()
new articleSearchInit()
new headerDropDownInit()
new langSwitchInit()
new featureItemsInit()
new playAudioInit()
new initSwiperResize()
new currencyListInit()
new initPaymnetToggle()
new HighlightText()

new ToggleItemsInit({
  wrapSelector: ".dial_choise_card-wrap",
  cardSelector: ".dial_choise_card",
  listSelector: ".card_list",
  showBtnSelector: ".toggle_list_button",
  hideThreshold: 4,
})

new ToggleItemsInit({
  wrapSelector: ".plan_body",
  cardSelector: ".plan_card",
  listSelector: ".card_list",
  showBtnSelector: ".toggle_list_button",
  hideThreshold: 5,
})

new ContactForm(".question_link", "#form-modal", "#contact-form-modal")
new ContactForm(".custom_link", "#form-modal2", "#contact-form-modal2")
new ContactForm(".btn_request", "#form-modal3", "#contact-form-modal3")

document.querySelectorAll(".btn_request").forEach((btn) => {
  btn.addEventListener("click", () => {
    if (document.querySelector("#contact-form-modal3 input[name=\"type\"]")) {
      document.querySelector("#contact-form-modal3 input[name=\"type\"]").value =
        btn.getAttribute("data-text_input") || ""
    }
  })
})

new CurrencyDropdown()

initSwiperProduct()

const swiperCall = new Swiper(".call_swiper", {
  modules: [Pagination],
  slidesPerView: 1,
  spaceBetween: 20,
  loop: true,
  speed: 1000,
  autoplay: {
    delay: 2500,
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },

  breakpoints: {
    320: {
      slidesPerView: 1,
      spaceBetween: 20,
    },
    576: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    1201: {
      slidesPerView: 1,
      spaceBetween: 20,
    },
  },
})

const swiperResult = new Swiper(".result_swiper", {
  modules: [Navigation, Pagination],
  slidesPerView: 3,
  spaceBetween: 20,
  loop: true,
  speed: 1000,
  autoplay: {
    delay: 2500,
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
  navigation: {
    nextEl: ".result_swiper .result-swiper-button-next",
    prevEl: ".result_swiper .result-swiper-button-prev",
  },
  breakpoints: {
    320: {
      slidesPerView: 1.06,
      spaceBetween: 20,
    },
    540: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    1024: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
  },
})

const swiperReviews = new Swiper(".reviews_swiper", {
  modules: [Navigation, Pagination],
  slidesPerView: 3,
  spaceBetween: 20,
  speed: 1000,
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
  navigation: {
    nextEl: ".reviews_head .swiper-button-next",
    prevEl: ".reviews_head .swiper-button-prev",
  },
  breakpoints: {
    320: {
      slidesPerView: 1.06,
      spaceBetween: 20,
    },
    768: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    1024: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
  },
})

const swiperReviewsSecond = new Swiper(".reviews_second_swiper", {
  modules: [Navigation, Pagination],
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
  navigation: {
    nextEl: ".review_second-swiper-button-next",
    prevEl: ".review_seconds-swiper-button-prev",
  },
  speed: 1000,
  autoplay: {
    delay: 2500,
  },
  loop: true,
  breakpoints: {
    320: {
      slidesPerView: 1,
      spaceBetween: 20,
    },
    768: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    1024: {
      slidesPerView: 1,
      spaceBetween: 40,
    },
  },
})

const swiperSimilarArticle = new Swiper(".article_swiper", {
  modules: [Navigation],
  spaceBetween: 20,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  breakpoints: {
    320: {
      slidesPerView: 1.06,
      spaceBetween: 20,
    },
    768: {
      slidesPerView: 2,
    },
    1024: {
      slidesPerView: 3,
    },
  },
})

let swiperProduct

function initSwiperProduct() {
  if (window.innerWidth < 769) {
    if (!swiperProduct) {
      swiperProduct = new Swiper(".product_swiper", {
        modules: [Navigation],
        spaceBetween: 20,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        breakpoints: {
          320: {
            slidesPerView: 1.06,
            spaceBetween: 20,
            centeredSlides: true,
          },
          576: {
            slidesPerView: 2,
            centeredSlides: false,
          },
        },
      })
    }
  } else {
    if (swiperProduct) {
      swiperProduct.destroy(true, true)
      swiperProduct = null
    }
  }
}

window.addEventListener("resize", initSwiperProduct)

document.addEventListener("DOMContentLoaded", function () {
  const slides = document.querySelectorAll(".reviews_slide-text")

  slides.forEach((slide) => {
    const textElement = slide.querySelector(".slide_text")
    const button = slide.querySelector(".toggle-button")

    if (textElement && button) {
      const isTextOverflowing = textElement.scrollHeight > textElement.clientHeight

      if (!isTextOverflowing) {
        button.style.display = "none"
      }
    }
  })
})

function toggleText(button) {
  const slideText = button.previousElementSibling

  const isExpanded = button.getAttribute("data-expanded") === "true"

  slideText.classList.toggle("expanded", !isExpanded)

  button.setAttribute("data-expanded", !isExpanded)

  button.textContent = isExpanded
    ? button.getAttribute("data-text-read-more")
    : button.getAttribute("data-text-read-less")
}

const buttons = document.querySelectorAll(".toggle-button")
buttons.forEach((button) => {
  button.addEventListener("click", function () {
    toggleText(button)
  })
})

window.addEventListener("load", () => {
  const jsTabs = document.querySelectorAll(".js-tabs")
  if (jsTabs) {
    jsTabs.forEach((container) => {
      const tabs = new Tabs({
        container: container,
        itemTriggerSelector: ".js-tab-btn",
        itemContentSelector: ".js-tabs-item",
        activeClass: "active",
        duration: 300,
      })
    })
  }
})

window.addEventListener("load", () => {
  const accordionContainers = document.querySelectorAll(".js-accordion")
  if (accordionContainers) {
    accordionContainers.forEach((container) => {
      const accordion = new Accordion({
        containerSelector: container,
        itemSelector: ".js-accordion-item",
        itemTriggerSelector: ".js-accordion-toggler",
        itemContentSelector: ".js-accordion-content",
        activeClass: "active",
        duration: 500,
        type: "multiply",
        onOpenBefore: (accordionItem) => {
          accordionItem.$item.classList.add("active")
        },
        // onOpenAfter: (accordionItem) => {
        //   console.log(accordionItem);
        // },
        // onCloseBefore: (accordionItem) => {
        //   console.log(accordionItem);
        // },
        // onCloseAfter: (accordionItem) => {
        //   console.log(accordionItem);
        // },
      })
    })
  }
})

gsap.registerPlugin(ScrollTrigger)

if (window.innerWidth > 1200) {
  const helpCards = document.querySelector(".help_cards")
  const article = document.querySelector(".article")

  if (helpCards && article) {
    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: helpCards,
        pin: true,
        start: "top 50px",
        endTrigger: article,
        end: "bottom bottom",
        scrub: 1,
      },
    })
  }
}

document.addEventListener("DOMContentLoaded", function () {
  const arrows = document.querySelectorAll("blockquote a")
  const dividers = document.querySelectorAll("blockquote .read_banner-text_wrap")

  arrows.forEach(function (link) {
    link.addEventListener("click", function () {
      const linkToClick = link.closest("blockquote").querySelector("a")
      if (linkToClick) {
        linkToClick.click()
      }
    })
  })
  dividers.forEach(function (divider) {
    const linkToClick = divider.closest("blockquote").querySelector("a")
    if (linkToClick) {
      divider.classList.add("showDivider")
    } else {
      divider.classList.remove("showDivider")
    }
  })
})

document.addEventListener("DOMContentLoaded", function () {
  const tabsBtns = document.querySelectorAll(".tabs__btn")
  const tabsHeadRow = document.querySelector(".tabs__head-row")

  if (!tabsBtns.length || !tabsHeadRow) return

  tabsBtns.forEach((btn, index) => {
    btn.addEventListener("click", function () {
      const activeBtn = document.querySelector(".tabs__btn.active")
      if (activeBtn) activeBtn.classList.remove("active")

      this.classList.add("active")

      const colors = ["var(--blue)", "var(--yellow)", "var(--green)", "var(--black2F)"]
      if (colors[index]) {
        tabsHeadRow.style.setProperty("--after-color", colors[index])
      }
    })
  })
})

if (window.innerWidth <= 576) {
  document.addEventListener("DOMContentLoaded", function () {
    const footer = document.querySelector("footer")
    const footerTitles = footer.querySelectorAll(".footer_title")
    const footerLists = footer.querySelectorAll(".footer_list")

    footerTitles.forEach((title, index) => {
      title.addEventListener("click", () => {
        title.classList.toggle("active")

        const correspondingList = footerLists[index]
        correspondingList.classList.toggle("open")
      })
    })
  })
}

document.addEventListener("DOMContentLoaded", function () {
  const trialBody = document.querySelector(".trial_without-additional-content")
  if (!trialBody) {
    return
  }
  const dialBtn = trialBody.querySelector(".btn")
  const dialModal = document.querySelector(".trial_modal")
  const shadow = document.querySelector(".drop-shadow")

  dialBtn.addEventListener("click", () => {
    dialModal.classList.add("open")
    shadow.classList.add("show")
    document.body.classList.add("lock")
  })
  document.addEventListener("keydown", (e) => {
    if (e.key === "Escape") {
      dialModal.classList.remove("open")
      shadow.classList.remove("show")
      document.body.classList.remove("lock")
    }
  })
})
function changePricingText() {
  const spansWrap = document.querySelector(".bottom_row")
  if (!spansWrap) return
  const spans = spansWrap.querySelectorAll("span")
  spans.forEach((span) => {
    const mobileText = span.getAttribute("data-mobile-text")
    const pcText = span.getAttribute("data-pc-text")

    if (window.innerWidth <= 992) {
      span.textContent = mobileText
    } else {
      span.textContent = pcText
    }
  })
}

window.addEventListener("resize", changePricingText)

document.addEventListener("DOMContentLoaded", changePricingText)

document.addEventListener("DOMContentLoaded", function () {
  let last_check = [...document.querySelectorAll(".icon-check_wrap")].pop()
  last_check && last_check.closest(".col_item").classList.add("last-child")
})

document.addEventListener("DOMContentLoaded", function () {
  const modalCookies = document.getElementById("modal_cookies")
  const modalCloseBtn = document.querySelector(".modal__close-btn")

  if (!localStorage.getItem("cookiesAccepted")) {
    setTimeout(() => {
      modalCookies.classList.add("show")
    }, 12000)
  }

  modalCloseBtn.addEventListener("click", function () {
    localStorage.setItem("cookiesAccepted", "true")
    modalCookies.classList.remove("show")
  })
})

const clientsMarquee = document.querySelector(".clients-marquee")
if (clientsMarquee) Marquee(clientsMarquee, 0.2, "left")
