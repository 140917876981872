export class playVideoInit {
  constructor() {
    this.playVideo()
  }

  playVideo() {
    const playBtns = document.querySelectorAll(".play_btn")
    const videos = document.querySelectorAll("video")

    if (playBtns.length && videos.length) {
      playBtns.forEach((playBtn, index) => {
        const video = videos[index]
        if (video) {
          playBtn.addEventListener("click", () => {
            if (video.paused) {
              video.play()
              video.muted = false
              video.controls = true
              playBtn.classList.add("hide_btn")
            } else {
              video.pause()
              playBtn.classList.remove("hide_btn")
            }
          })

          video.addEventListener("ended", () => {
            playBtn.classList.remove("hide_btn")
          })
        }
      })
    }
  }
}
