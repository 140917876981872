import { fadeIn, fadeOut } from "../utils/fade"

export class ToggleItemsInit {
  /**
   * @param {Object} options - Configuration options for ToggleItemsInit.
   * @param {string} options.wrapSelector - Selector for the wrapper element.
   * @param {string} options.cardSelector - Selector for the card element.
   * @param {string} options.listSelector - Selector for the list element.
   * @param {string} options.showBtnSelector - Selector for the show button element.
   * @param {number} [options.hideThreshold=4] - Number of items to show before hiding the rest.
   */
  constructor({ wrapSelector, cardSelector, listSelector, showBtnSelector, hideThreshold = 4 }) {
    this.wrapSelector = wrapSelector
    this.cardSelector = cardSelector
    this.listSelector = listSelector
    this.showBtnSelector = showBtnSelector
    this.hideThreshold = hideThreshold

    this.init()
  }

  init() {
    const cardsWraps = document.querySelectorAll(this.wrapSelector)

    const processWraps = (index) => {
      if (index >= cardsWraps.length) return
      this.showItems(cardsWraps[index])
      requestAnimationFrame(() => processWraps(index + 1))
    }

    processWraps(0)
  }

  showItems(cardsWrap) {
    if (!cardsWrap) return

    const showBtns = cardsWrap.querySelectorAll(this.showBtnSelector)
    if (!showBtns.length) return

    const cardLists = cardsWrap.querySelectorAll(this.listSelector)
    if (!cardLists.length) return

    cardLists.forEach((cardList) => {
      const items = cardList.children
      if (items.length === 0) return

      const toggleItemsVisibility = (items, hideThreshold, showAll) => {
        Array.from(items).forEach((item, index) => {
          if (showAll || index < hideThreshold) {
            const delay = 100 * index
            setTimeout(() => {
              if (item.classList.contains("hide")) {
                fadeIn(
                  item,
                  300,
                  () => {
                    item.classList.remove("hide")
                  },
                  "flex",
                )
              }
            }, delay)
          } else {
            fadeOut(item, 300, () => {
              item.classList.add("hide")
            })
          }
        })
      }

      showBtns.forEach((showBtn) => {
        const textShow = showBtn.getAttribute("data-text-show")
        const textHide = showBtn.getAttribute("data-text-hide")

        if (showBtn.dataset.listenerAdded === "true") return

        showBtn.addEventListener("click", () => {
          const parentCardList = showBtn.closest(this.cardSelector).querySelector(this.listSelector) // Ищем список внутри родительской карточки
          const items = parentCardList.children

          if (showBtn.dataset.state === "show-all") {
            toggleItemsVisibility(items, this.hideThreshold, true)
            fadeIn(showBtn, 300, () => {
              showBtn.dataset.state = "show-less"
              showBtn.textContent = textHide
            })
          } else {
            toggleItemsVisibility(items, this.hideThreshold, false)
            fadeIn(showBtn, 300, () => {
              showBtn.dataset.state = "show-all"
              showBtn.textContent = textShow
            })
          }
        })

        showBtn.dataset.listenerAdded = "true"
      })

      if (items.length > this.hideThreshold) {
        toggleItemsVisibility(items, this.hideThreshold, false)
      }
    })
  }
}
