export class showBurger {
  constructor() {
    this.burgerBtn = document.querySelector(".burger-btn")
    this.menu = document.querySelector(".header__nav-wrap")
    this.burgerInit()
  }

  burgerInit() {
    this.burgerBtn.addEventListener("click", () => {
      this.burgerBtn.classList.toggle("active")
      this.menu.classList.toggle("activeMenu")
      document.body.classList.toggle("lock")
    })
  }
}
