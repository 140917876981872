export class ShowProposalForm {
  constructor() {
    this.initProposalForms()
  }

  initProposalForms() {
    // Select all proposal buttons and popup elements
    const proposalBtns = document.querySelectorAll(".proposal_btn")
    const proposalPopUps = document.querySelectorAll(".proposal_popup")
    const closeProposalBtns = document.querySelectorAll(".close_popUp")
    const proposalContainers = document.querySelectorAll(".proposal_popup-container")

    if (!proposalBtns.length || !proposalPopUps.length) {
      return
    }

    // Loop through each proposal button and attach event listeners
    proposalBtns.forEach((proposalBtn, index) => {
      const proposalPopUp = proposalPopUps[index]
      const closeProposalBtn = closeProposalBtns[index]
      const proposalContainer = proposalContainers[index]

      // Open the proposal popup when the button is clicked
      proposalBtn.addEventListener("click", () => {
        proposalPopUp.classList.add("openProposalPopUp")
        proposalContainer.classList.add("showProposalContainer")
        document.body.classList.add("lock")
      })

      // Close the proposal popup when the close button is clicked
      closeProposalBtn.addEventListener("click", () => {
        proposalPopUp.classList.remove("openProposalPopUp")
        proposalContainer.classList.remove("showProposalContainer")
        document.body.classList.remove("lock")
      })
    })

    // Close the proposal popup if the Escape key is pressed
    document.addEventListener("keydown", function (event) {
      if (event.key === "Escape") {
        proposalPopUps.forEach((proposalPopUp, index) => {
          proposalPopUp.classList.remove("openProposalPopUp")
          proposalContainers[index].classList.remove("showProposalContainer")
        })
        document.body.classList.remove("lock")
      }
    })
  }
}
