export class headerDropDownInit {
  constructor() {
    this.headerDropDown()
  }

  headerDropDown() {
    const headerDropWrap = document.querySelector(".header__menu")
    const dropItems = headerDropWrap.querySelectorAll(".dropdown")
    const closeBtns = headerDropWrap.querySelectorAll(".close_sub-menu") 
    const headerBackdrop = document.querySelector(".header_backdrop")
 
    dropItems.forEach((item) => {
      const dropBtn = item.querySelector(".drop_btn")
 
      if (dropBtn) {
        dropBtn.addEventListener("click", (e) => {
          e.stopPropagation()
 
          if (window.innerWidth < 992) {
            dropBtn.scrollIntoView({
              behavior: "smooth",
              block: "start",
              inline: "nearest",
            })
          }
 
          const isOpen = item.classList.contains("open")
          item.classList.toggle("open")
 
          if (window.innerWidth > 992) {
            if (isOpen) {
              headerBackdrop.classList.remove("show")
              document.body.classList.remove("_lock")
            } else {
              headerBackdrop.classList.add("show")
              document.body.classList.add("_lock")
            }
          }
 
          dropItems.forEach((otherItem) => {
            if (otherItem !== item) {
              otherItem.classList.remove("open")
            }
          })
        })
      }
    })
 
    closeBtns.forEach((closeBtn) => {
      closeBtn.addEventListener("click", (e) => {
        e.stopPropagation()
 
        const parentDropdown = closeBtn.closest(".dropdown")
        if (parentDropdown) {
          parentDropdown.classList.remove("open")
        }
 
        if (window.innerWidth > 992) {
          headerBackdrop.classList.remove("show")
          document.body.classList.remove("_lock")
        }
      })
    })
 
    headerBackdrop.addEventListener("click", () => {
      dropItems.forEach((item) => {
        item.classList.remove("open")
      })
      headerBackdrop.classList.remove("show")
      if (window.innerWidth > 992) {
        document.body.classList.remove("_lock")
      }
    })
 
    document.addEventListener("click", (e) => {
      if (!headerDropWrap.contains(e.target)) {
        dropItems.forEach((item) => {
          item.classList.remove("open")
        })
        headerBackdrop.classList.remove("show")
        if (window.innerWidth > 992) {
          document.body.classList.remove("_lock")
        }
      }
    })
  }
 
}
