import { fadeIn } from "../utils/fade"

export class CurrencyDropdown {
  constructor() {
    this.tabElements = document.querySelectorAll(".plan") // Получаем все элементы с классом .plan (все табы)
    this.initializeCurrencies()
    this.initAddonToggleBtns()
  }

  // Инициализация валюты на странице
  initializeCurrencies() {
    // Получаем сохраненную валюту и символ валюты из localStorage или используем значения по умолчанию
    const savedCurrency = localStorage.getItem("selectedCurrency") || "USD" // Валюта по умолчанию
    const savedCurrencySymbol = localStorage.getItem("selectedCurrencySymbol") || "$" // Символ валюты по умолчанию

    this.tabElements.forEach((tab) => {
      const currencyDropdown = tab.querySelector(".currency-dropdown") // Находим валютный выпадающий список
      const currencyList = currencyDropdown.querySelector(".dropdown-list") // Список валют
      const currencyButton = currencyDropdown.querySelector(".dropdown-button span") // Кнопка для отображения выбранной валюты

      // Устанавливаем валюту и символ для текущего списка
      this.updateCurrency(currencyButton, savedCurrency) // Обновляем валюту на кнопке
      this.updateCurrencySymbol(savedCurrencySymbol) // Обновляем символ валюты на странице

      // Добавляем обработчики событий для выпадающего списка
      this.addEventListeners(currencyList, currencyButton)
    })
    this.updatePlanPrice(savedCurrency)
    this.updateAddonPrice(savedCurrency)

    document.querySelectorAll(".current-item").forEach((item) => {
      item.classList.remove("current-item")
    })

    document.querySelectorAll(".currency-dropdown .dropdown-item").forEach((currencyItem) => {
      if(currencyItem && currencyItem.getAttribute("data-code").toLowerCase() === savedCurrency.toLowerCase()) {
        currencyItem.classList.add("current-item")
      }
    })
  }

  // Сохранение выбранной валюты и символа в localStorage
  saveCurrencyToLocalStorage(currencyCode, currencySymbol) {
    localStorage.setItem("selectedCurrency", currencyCode)
    localStorage.setItem("selectedCurrencySymbol", currencySymbol)
  }

  // Добавление обработчиков событий для валют
  addEventListeners(currencyList, currencyButton) {
    currencyList.querySelectorAll(".dropdown-item").forEach((currencyItem) => {
      currencyItem.addEventListener("click", (e) => {
        this.changeCurrency(e.target, currencyButton)
      })
    })
  }

  // Изменение валюты
  changeCurrency(target, currencyButton) {
    const selectedCurrencyCode = target.textContent.trim() // Получаем код валюты (например, USD, EUR)
    const currencySymbol = target.getAttribute("data-value") // Получаем символ валюты из атрибута data-value
    document.querySelector(".current-item").classList.remove("current-item")
    target.classList.add("current-item")

    // Сохраняем выбранную валюту и символ в localStorage
    this.saveCurrencyToLocalStorage(selectedCurrencyCode, currencySymbol)

    // Обновляем валюту на кнопке для всех планов
    this.updateCurrency(currencyButton, selectedCurrencyCode)

    // Обновляем валюту для всех других кнопок
    this.updateAllCurrencies(selectedCurrencyCode)

    // Обновляем символ валюты на странице
    this.updateCurrencySymbol(currencySymbol)

    // Установка цены в выбранной валюте
    this.updatePlanPrice(selectedCurrencyCode)

    // Обновление цены аддонов
    this.updateAddonPrice(selectedCurrencyCode)
  }

  // Обновление валюты на кнопке для одного плана
  updatePlanPrice(selectedCurrencyCode) {
    document.querySelectorAll(".dynamic_price_change").forEach((item) => {
      item.textContent = ` ${item.getAttribute(`data-price-${selectedCurrencyCode.toLowerCase()}`)}`
    })
  }

  // Обновление валюты на кнопке для одного плана
  updateCurrency(currencyButton, currencyCode) {
    currencyButton.textContent = currencyCode
  }

  // Обновление валюты на всех кнопках на странице
  updateAllCurrencies(currencyCode) {
    const allCurrencyButtons = document.querySelectorAll(".currency-dropdown .dropdown-button span")
    allCurrencyButtons.forEach((button) => {
      button.textContent = currencyCode
    })
  }

  // Обновление символа валюты на всех элементах с data-currency-symbol
  updateCurrencySymbol(currencySymbol) {
    const priceElements = document.querySelectorAll(".price-symbol span")
    priceElements.forEach((element) => {
      element.style.setProperty("--currency-symbol", `"${currencySymbol}"`)
    })
  }

  // Обновление аддонов
  initAddonToggleBtns() {
    const addonsCards = document.querySelectorAll(".addons_card")
    addonsCards.forEach((card) => {
      const toggle = card.querySelector(".toggler span")
      const togglerItems = card.querySelectorAll(".card_toggler-item")
      const cardPrice = card.querySelector(".card_price span")

      if (!toggle || togglerItems.length < 2 || !cardPrice) return

      toggle.addEventListener("click", () => {
        if (toggle.classList.contains("active")) {
          toggle.classList.remove("active")
          togglerItems[0].classList.add("active")
          togglerItems[1].classList.remove("active")

          fadeIn(cardPrice, 300, () => {
            cardPrice.textContent = cardPrice.getAttribute(`data-price-regular-${document.querySelector(".current_selected_currency").textContent.toLowerCase() || "usd"}`)
          })
        } else {
          toggle.classList.add("active")
          togglerItems[0].classList.remove("active")
          togglerItems[1].classList.add("active")

          fadeIn(cardPrice, 300, () => {
            cardPrice.textContent = cardPrice.getAttribute(`data-price-advanced-${document.querySelector(".current_selected_currency").textContent.toLowerCase() || "usd"}`)
          })
        }
      })
    })
  }

  updateAddonPrice(currencySymbol) {
    document.querySelectorAll(".addons_card").forEach((AddonCard) => {
      let current_price = AddonCard.querySelector(".card_price span")
      if(current_price) {
        if(AddonCard.querySelector(".card_toggler .active") !== null) {
          current_price.textContent = current_price.getAttribute(`data-price-advanced-${currencySymbol.toLowerCase() || "usd"}`)
        } else {
          current_price.textContent = current_price.getAttribute(`data-price-regular-${currencySymbol.toLowerCase() || "usd"}`)
        }
      }
    })
  }
}
