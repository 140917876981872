export const Marquee = (container, speed, scrollDirection) => {
  let direction = "left"
  if (scrollDirection) direction = scrollDirection
  const parentSelector = container
  if (!parentSelector) return
  if (direction == "right") {
    parentSelector.classList.add("right-direction")
  }
  const clone = parentSelector.innerHTML
  const firstElement = parentSelector.children[0]
  if (!firstElement) return
  let i = 0
  if (direction == "right") {
    parentSelector.insertAdjacentHTML("afterbegin", clone)
    parentSelector.insertAdjacentHTML("afterbegin", clone)
  } else {
    parentSelector.insertAdjacentHTML("beforeend", clone)
    parentSelector.insertAdjacentHTML("beforeend", clone)
  }

  setInterval(function () {
    if (direction == "right") {
      firstElement.style.marginRight = `-${i}px`
    } else {
      firstElement.style.marginLeft = `-${i}px`
    }
    if (i > firstElement.clientWidth) {
      i = 0
    }
    i = i + speed
  }, 0)
}
