export class articleSearchInit {
  constructor() {
    this.openArticleSearch()
  }

  openArticleSearch() {
    const searchBtn = document.querySelector(".articles_search-btn")
    if (!searchBtn) {
      return
    }
    const searchInput = document.getElementById("article_search-wrap")
  
    searchBtn.addEventListener("click", (e) => {
      e.stopPropagation()
      searchInput.classList.toggle("openInput")
      searchBtn.classList.toggle("activeSearchBtn")
    })
  
    document.addEventListener("click", (e) => {
      if (!searchInput.contains(e.target) && !searchBtn.contains(e.target)) {
        searchInput.classList.remove("openInput")
        searchBtn.classList.remove("activeSearchBtn")
      }
    })
  }
}
